<template>
  <div class="outer_box flex-align-center">
    <!-- :class="{'centered':centeredStatus}" -->
    <div
      class="img_box"
      ref="bigImage"
      :style="{
        transform: 'scale(' + imgList[Index].multiples + ')',
        transformOrigin: 'center center',
      }"
      v-drag
      @mousewheel="rollImg(this)"
    >
      <img
        :src="imgList[Index].img1"
        :style="{ transform: 'rotateZ(' + imgList[Index].deg + 'deg)' }"
        @load="loadImg"
      />
    </div>
    <div class="mianpoa flex-align-between">
      <div
        class="prev"
        :class="{ prevAff: Index != 0 }"
        @click="loadType ? previewBtn(0) : ''"
      ></div>
      <div
        class="next"
        :class="{ nextAff: Index != imgList.length - 1 }"
        @click="loadType ? previewBtn(1) : ''"
      ></div>
      <div
        :style="{ 'background-image': 'url(' + item + ')' }"
        v-for="(item, index) in previewImg"
        :key="index"
        @click="loadType ? previewBtn(index + 2) : ''"
      ></div>
    </div>
  </div>
</template>
<script>
import { createLogger } from "vuex";
export default {
  data() {
    return {
      loadType: false,
      imgList: [
        {
          img: "",
          deg: 0,
          L: null,
          T: null,
          multiples: 1,
        },
      ],
      imgSrc: "",
      deg: 0,
      multiples: 1,
      previewL: require("../../assets/assistant/previewLa.png"),
      previewR: require("../../assets/assistant/previewR.png"),
      previewImg: [
        require("../../assets/assistant/previewJ.png"),
        require("../../assets/assistant/previewJIE.png"),
        require("../../assets/assistant/rotate.png"),
        require("../../assets/assistant/reset3.png"),
        // require("../../assets/assistant/ai.png"),
      ],
      Index: 0,
      prevType: false,
      nextType: false,
      centeredStatus: true,
    };
  },
  props: ["imgArr", "lantern", "options"],
  directives: {
    //自定义拖动
    drag: {
      inserted: function (el, binding, vnode) {
        let oDiv = el; //当前元素
        let that = this;
        oDiv.onmousedown = function (e) {
          e.preventDefault();
          // vnode.context.centeredStatus = false;

          let bw = document.body.clientWidth;
          let bh = document.body.clientHeight;
          //鼠标按下，计算当前元素距离可视区的距离
          let disX = e.clientX - oDiv.offsetLeft;
          let disY = e.clientY - oDiv.offsetTop;

          // 计算两边坐标
          document.onmousemove = function (e) {
            let l = 0,
              t = 0;
            // 拖动边界
            if (e.clientX >= bw) {
              l = bw - disX;
            } else if (e.clientX <= 0) {
              l = 0 - disX;
            } else {
              l = e.clientX - disX;
            }
            if (e.clientY >= bh) {
              t = bh - disY;
            } else if (e.clientY <= 0) {
              t = 0 - disY;
            } else {
              t = e.clientY - disY;
            }
            //移动当前元素
            vnode.context.imgList[vnode.context.Index].L = l;
            vnode.context.imgList[vnode.context.Index].T = t;
            oDiv.style.left = l + "px";
            oDiv.style.top = t + "px";
          };
          // 鼠标停止移动时，事件移除
          document.onmouseup = function (e) {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },
  methods: {
    // 放大
    magnify() {
      if (this.imgList[this.Index].multiples >= 3) {
        return;
      }
      this.imgList[this.Index].multiples += 0.25;
    },
    // 缩小
    shrink() {
      if (this.imgList[this.Index].multiples <= 0.5) {
        return;
      }
      this.imgList[this.Index].multiples -= 0.25;
    },
    // 旋转
    rotate() {
      console.log("this.imgList", this.imgList);
      this.imgList[this.Index].deg -= 90;
      if (this.imgList[this.Index].deg >= 360) {
        this.imgList[this.Index].deg = 0;
      }
      this.configWorkParams(
        this.imgList[this.Index].id,
        this.imgList[this.Index].deg
      );
    },
    //记录用户操作
    async configWorkParams(id, angle) {
      let data = {
        id: id,
        type: this.options == 0 ? 1 : 2,
        params: JSON.stringify({
          deg: angle,
        }),
      };
      // console.log('options',this.options)
      // 学生传1 我的传2
      console.log("data", data);
      let resData = await this.$Api.Myclass.configWorkParams(data);
      console.log("resData", resData);
    },
    //鼠标缩放
    rollImg() {
      event.wheelDelta == 120 ? this.magnify() : this.shrink();
    },
    //初始化
    reset() {
      this.imgList[this.Index].deg = 0;
      this.imgList[this.Index].multiples = 1;
      let dom = document.getElementsByClassName("img_box")[0];
      dom.style.left = "auto";
      dom.style.top = "auto";
      this.imgList[this.Index].L = null;
      this.imgList[this.Index].T = null;
    },
    //ai测评
    evaluation() {
      this.$emit("fatherMethod");
    },

    //图片预览按钮
    previewBtn(index) {
      switch (index) {
        case 0:
          this.prev();
          break;
        case 1:
          this.next();
          break;
        case 2:
          this.magnify();

          break;
        case 3:
          this.shrink();

          break;
        case 4:
          this.rotate();

          break;
        case 5:
          this.reset();
          break;
        // case 6:
        //   this.evaluation();
        //   break;
      }
    },
    //生成数据
    //上一个
    prev() {
      this.$emit("lantern", true);
      if (this.Index > 0) {
        this.Index--;
        this.Imgstate(this.Index);
      }
    },
    //下一个
    next() {
      this.$emit("lantern", false);
      if (this.Index < this.imgList.length - 1) {
        this.Index++;
        this.Imgstate(this.Index);
      }
    },
    //回显状态
    Imgstate(index) {
      this.$nextTick(() => {
        let dom = document.getElementsByClassName("img_box")[0];
        if (this.imgList[this.Index].T != null) {
          dom.style.left = this.imgList[index].L + "px";
          dom.style.top = this.imgList[index].T + "px";
        } else {
          dom.style.left = "auto";
          dom.style.top = "auto";
        }
      });
    },
    loadImg() {
      this.loadType = true;
    },
    centeredChang() {
      this.centeredStatus = false;
    },
  },
  created() {
    this.imgList = this.imgArr;
  },
  mounted() {},
  watch: {
    imgArr: {
      handler(oldVal, newVal) {
        this.imgList = oldVal;
      },
      immediate: true,
    },
    Index: {
      handler(oldVal, newVal) {
        // console.log('oldVal', oldVal)
        // console.log('this.imgArr2222222222', this.imgArr)
        this.Imgstate(oldVal);
      },
      immediate: true,
    },
  },
};
</script>
<style lang='less' scoped>
@import "./less/ViewerRecord";
</style>